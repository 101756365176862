/** @format */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import HyperLinkText from "../components/HyperLinkText"
import ImageInset from "../components/ImageInset"
import Features from "../components/Features"

// eslint-disable-next-line
export const DesignPrinciplesPageTemplate = ({ title, blurbThing }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              {title}
            </h2>
            <p className="subtitle has-text-grey">
              What makes a great flag? Flag experts publish design principles to
              guide the development of effective flags. These principles
              emphasize simplicity and meaningful symbolism. A great city flag
              is simple but carries relevant symbolism to inspire civic pride.​
            </p>
          </div>
          <Features
            gridItems={blurbThing.blurbs}
            description={blurbThing.description}
          />

          <div className="column is-10 is-8-desktop mb-5">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Good Flag, Bad Flag
            </h2>
            <p className="subtitle has-text-grey mb-5">
              The study of flags and flag design is called <em>vexillology</em>.
              The{" "}
              <HyperLinkText
                link={"https://nava.org/"}
                text={"North American Vexillological Association"}
              />{" "}
              outlines a set of good flag design principles in the charming
              booklet <em>Good Flag, Bad Flag</em>.
            </p>
            <div className="box has-background-light">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4">
                  <img
                    className="image is-fullwidth"
                    style={{ objectFit: "cover" }}
                    src="/img/gfbf.png"
                    alt=""
                  />
                </div>
                <div className="column is-8">
                  <p className="is-size-5">
                    Read a description of good flag design principles and see
                    examples of good and bad flags in the North American
                    Vexillological Association’s{" "}
                    <em>
                      <a
                        className=""
                        href="/assets/GFBF_English.pdf"
                        target={"_blank"}
                      >
                        Good Flag, Bad Flag
                      </a>
                    </em>{" "}
                    booklet.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              Flags that follow good flag design principles are highly
              effective.​
            </p>
          </div>

          <ImageInset
            figureText={"Some well-designed city flags​​"}
            images={[
              "/img/principles-amsterdam.png",
              "/img/principles-springfield.png",
              "/img/principles-milwaukee-new.png",
            ]}
          />

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              Flags that deviate from these principles tend to be forgettable or
              just plain lousy.​
            </p>
          </div>

          <ImageInset
            figureText={"Some poorly-designed city flags​​​"}
            images={[
              "/img/principles-grand_rapids.png",
              "/img/principles-glendale.png",
              "/img/principles-milwaukee-old (1).png",
            ]}
          />

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              The Raleigh{" "}
              <HyperLinkText link={"/background"} text={"flag of 1960"} />{" "}
              contains important symbolism. Its designers sought to convey
              Raleigh’s identity as the City of Oaks and acknowledge the
              eponymic Sir Walter Raleigh. Unfortunately, the flag of 1960
              doesn’t follow good design principles. It’s complex, uses too many
              colors, has unreadable letters and numbering, and is generally
              indistinct and uninspiring.
            </p>
            <p className="subtitle has-text-grey mb-5">
              The proposed new Raleigh flag design preserves the intent of the
              1960 flag while following good flag design principles. It’s a
              banner worthy of the city’s pride.
            </p>
          </div>
        </div>
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <Link className="button is-primary" to="/the-new-flag">
              Read about the new Raleigh flag design​
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

DesignPrinciplesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  blurbThing: PropTypes.shape({
    image: PropTypes.object,
    blurbs: PropTypes.array,
  }),
}

const DesignPrinciplesPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <DesignPrinciplesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        blurbThing={frontmatter.blurbThing}
      />
    </Layout>
  )
}

DesignPrinciplesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default DesignPrinciplesPage

export const DesignPrinciplesPageQuery = graphql`
  query DesignPrinciplesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        blurbThing {
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          blurbs {
            icon
            title
            text
          }
          heading
          description
        }
      }
    }
  }
`
