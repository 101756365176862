import { Link } from "gatsby"
import React from "react"

const HyperLinkText = ({ link, text }) => {
  return (
    <Link to={link} target="_blank">
      {text}
    </Link>
  )
}

export default HyperLinkText
