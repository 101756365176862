import React from "react"

const ImageInset = ({ figureText, images }) => {
  return (
    <div className="column is-10 is-8-desktop mb-5">
      <div className="mb-4 box p-3 is-relative has-background-light">
        <div className="columns is-multiline is-vcentered">
          {images.map((image, i) => (
            <div
              key={i}
              className={`column ${images.length === 3 ? "is-4" : "is-6"}`}
            >
              <img className="image is-fullwidth" src={image} alt="" />
            </div>
          ))}
        </div>
      </div>
      {figureText ? (
        <p className="has-text-grey has-text-centered">{figureText}</p>
      ) : null}
    </div>
  )
}

export default ImageInset
